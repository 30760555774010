import styles from './StickyContainer.module.scss'
import React, {ReactElement} from "react";

export default function StickyContainer(
  {
    component,
    children,
    className,
    hideStickOnMobile
  }: {
    component: ReactElement,
    hideStickOnMobile?: boolean,
    className?: string,
    children: ReactElement[]|ReactElement,
}) {

  return <div className={`${styles.container} ${className || ''}`}>
    <div className={styles.content}>{children}</div>
    <div className={`${styles.sticky} ${hideStickOnMobile ? styles.hideStickyOnMobile: ''}`}>
        {component}
    </div>

  </div>
}
